
import React from 'react';
import '../CSS/Footer.css';
import linkedinicon from '../images/svg/linkedin.svg';
import githubicon from '../images/svg/github.svg';
import htbicon from '../images/svg/HTB.svg';
import supp from '../images/supp.jpg';


const Footer = () => {
  const title = 'Helpful\'s Domain';
  return (

    <footer className="footer-distributed">
      <div className="footer-right">

        <a href="#" title="About Me">
          <i className="fa fa-supp">
            <img src={supp} className="App-logo" alt="logo" /></i></a>
        <a href="https://app.hackthebox.com/users/531186" title="Hack The Box">
          <i className="fa fa-htb">
            <img src={htbicon} className="App-logo" alt="logo" /></i></a>
        <a href="https://www.linkedin.com/in/kn%C3%BAt-joensen-b32935233/" title="Linkedin">
          <i className="fa fa-linkedin">
            <img src={linkedinicon} className="App-logo" alt="logo" /></i></a>
        <a href="https://github.com/HelpfulFriend" title="Github">
          <i className="fa fa-github">
            <img src={githubicon} className="App-logo" alt="logo" /></i></a>

      </div>

      <div className="footer-left">

        <p className="footer-links">
          <a href="#" className="link-1" >Blog</a>
          <a href="#aboutme">About</a>
          <a href="#">Contact</a>
          <a href="https://search.kys.enterprises">Search</a>
        </p>

        <p>{title} &copy; 2022</p>
      </div>
    </footer>
  );
};

export default Footer;
