
import React from 'react';
import '../CSS/Header.css';
import DarkMode from './DarkMode';


const Header = () => {
  const title = 'Helpful\'s Domain';
  return (

    <header>
      <nav className="nav">
        <h1 className="nav-heading">{title}</h1>
        <DarkMode />
        <a className="nav-button">Blog</a>
        <a className="nav-button">About</a>
        <a className="nav-button">Contact</a>
        <a className="nav-button" href="https://search.kys.enterprises">Search</a>
      </nav>
    </header>
  );
};

export default Header;
