import React from 'react';
import '../CSS/Background.css';


const Background = () => {
  return (
    <body className="Background"></body>

  );
};

export default Background;
