/* eslint-disable linebreak-style */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable linebreak-style */
import '../CSS/DarkMode.css';
import {ChangeEventHandler} from 'react';
import moon from '../images/svg/moon.svg';
import sun from '../images/svg/sun.svg';

// 1
const setDark = () => {
  // 2
  localStorage.setItem('theme', 'dark');

  // 3
  document.documentElement.setAttribute('data-theme', 'dark');
};

const setLight = () => {
  localStorage.setItem('theme', 'light');
  document.documentElement.setAttribute('data-theme', 'light');
};

// 4
const storedTheme = localStorage.getItem('theme');

const prefersDark =
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const defaultDark =
  storedTheme === 'dark' || (storedTheme === null && prefersDark);

if (defaultDark) {
  setDark();
}

// 5
const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
  if (e.target.checked) {
    setDark();
  } else {
    setLight();
  }
};

const DarkMode = () => {
  return (
    <div className="toggle-theme-wrapper">
      <span><img src={sun} className="App-logo" alt="logo" /></span>
      <label className="toggle-theme" htmlFor="checkbox">
        <input title="input"
          type="checkbox"
          id="checkbox"

          // 6
          onChange={toggleTheme}
          defaultChecked={defaultDark}
        />
        <div className="slider round"></div>
      </label>
      <span><img src={moon} className="App-logo" alt="logo" /></span>
    </div>
  );
};

export default DarkMode;
export {};
