import React from 'react';
// import './CSS/App.css';
import Header from './TSX/Header';
import Footer from './TSX/Footer';
import Background from './TSX/RenderBackground';
// import Body from './TSX/Body';
/**
 * Sets up basic react tsx file.
 * @param {int} logo is the react logo.
 * @return {int} The layout is what comes out of app as root.
 */
function App() {
  return (
    <div className="App">
      <Header />

      <Background />

      <Footer />
    </div>
  );
}


export default App;
